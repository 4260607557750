import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router/index.js";
import { store } from "./store/index.js";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "./main.css";
import Notifications from "@kyvg/vue3-notification";
import i18n from "./plugins/i18n";
import { progressBarOptions, progressBar } from "./plugins/progressBar.js";
import print from "vue3-print-nb";

export default createApp(App)
  .use(router)
  .use(store)
  .use(Antd)
  .use(i18n)
  .use(Notifications, {
    duration: 5000,
    position: "bottom left",
  })
  .use(progressBar, progressBarOptions)
  .use(print)
  .mount("#app");

store.subscribe((mutation, state) => {
  if (JSON.stringify(state.agendaForm) !== "{}") {
    localStorage.setItem("agendaForm", JSON.stringify(state.agendaForm));
  }
});
