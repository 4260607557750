import { getEnv } from "@/helpers";
import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const locale = key
      .replaceAll("/", "")
      .replaceAll(".json", "")
      .replaceAll(".", "");
    messages[locale] = locales(key);
  });
  return messages;
}

export default createI18n({
  locale: getEnv("VUE_APP_I18N_LOCALE") || "en",
  fallbackLocale: getEnv("VUE_APP_I18N_FALLBACK_LOCALE") || "en",
  messages: loadLocaleMessages(),
});
