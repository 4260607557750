<template>
  <div class="top-bar" v-if="showNavSlider">
    <nav class="w-full flex flex-wrap items-center justify-between px-2 py-3">
      <div
        class="container px-4 mx-auto flex flex-wrap items-center justify-between"
      >
        <div class="mx-auto md:mx-0">
          <img
            v-if="isset(companyProperties.LOGO)"
            onerror="(this.style.display = 'none')"
            :src="companyProperties.LOGO"
            alt="logo"
            class="w-auto h-16"
          />
        </div>

        <div class="mx-auto md:mx-0">
          <h1 class="text-white font-semibold text-2xl md:text-3xl"></h1>
        </div>

        <div class="mx-auto md:mx-0 mt-5 md:mt-0 items-center">
          <LanguageSwitch />
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LanguageSwitch from "./LanguageSwitch.vue";
import { isset } from "@/helpers";

export default {
  name: "NavBar",
  components: { LanguageSwitch },
  setup() {
    return {
      isset,
    };
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["showNavSlider", "headingText", "companyProperties"]),
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
