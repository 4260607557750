import VueProgressBar from "@aacassandra/vue3-progressbar";
import { store } from "@/store";

const progressBarColors = store.state.colorCodes.progressBar;

export const progressBarOptions = {
  color: progressBarColors.main,
  failedColor: progressBarColors.failed,
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

export const progressBar = VueProgressBar;
