<template>
  <div>&#160;</div>
  <!-- <footer class="relative bg-gray-300 pb-6">
    <div class="container mx-auto px-4">
      <hr class="border-gray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-gray-600 font-semibold py-1">
            Copyright © {{ date }} {{ $t("app_name") }}.
          </div>
        </div>
      </div>
    </div>
  </footer> -->
</template>
<script>
export default {
  name: "FooterNav",

  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
