import * as VueRouter from "vue-router";
import { isset } from "@/helpers";
function setComponent(path, name) {
  // let companyId = localStorage.getItem("companyId");
  path = isset(path) && path != "" ? "/" + path : "";
  return () => import("@/views" + path + "/" + name + ".vue");
}

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: setComponent("Home", "HomePage"),
  },
  {
    path: "/appointment/location",
    name: "SelectGarage",
    component: setComponent("Appointment", "SelectGarageLocation"),
  },
  {
    path: "/appointment/vehicle-information",
    name: "VehicleInformation",
    component: setComponent("Appointment", "VehicleInformation"),
  },
  {
    path: "/appointment/work-type",
    name: "WorkType",
    component: setComponent("Appointment", "WorkType"),
  },
  {
    path: "/appointment/time",
    name: "TimeSlot",
    component: setComponent("Appointment", "TimeSlot"),
  },
  {
    path: "/appointment/confirmation",
    name: "AppointmentConfirmation",
    component: setComponent("Appointment", "AppointmentConfirmation"),
  },
  {
    path: "/appointment/booked",
    name: "AppointmentBooked",
    component: setComponent("Appointment", "AppointmentBooked"),
  },
  {
    path: "/appointment/detail",
    name: "AppointmentDetail",
    component: setComponent("Appointment", "AppointmentDetail"),
  },
  {
    path: "/landing",
    name: "LandingPage",
    component: setComponent("", "LandingPage"),
  },
];

export const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});
