import axios from "axios";
import app from "@/main";
import { getEnv } from "@/helpers";

const config = {
  baseURL: getEnv("VUE_APP_APP_URL"),
};

export const http = axios.create(config);

http.interceptors.request.use(function (config) {
  let token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // add company_id to header from env
  config.headers["company-identifier"] = getEnv("VUE_APP_COMPANY_IDENTIFIER");
  config.headers["user-language"] = app.$store.state.selectedLanguage;

  app.$Progress.start();

  return config;
});

http.interceptors.response.use(
  function (response) {
    app.$Progress.finish();

    return response.data;
  },
  function (error) {
    if (error.message !== "canceled") app.$Progress.fail();

    // Check if there is internet connection error
    if (!error.response && error.message === "Network Error") {
      app.$notify({
        type: "error",
        title: app.$t("internet_connection_error"),
        duration: 5000,
      });
    }

    return Promise.reject(error);
  }
);
