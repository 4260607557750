import app from "./main.js";

export const filterAndConvertHttpErrorsToSingleString = (error) => {
    let errors = [];
    const errorMessages =
        error?.response?.data?.errors ||
        error?.response?.data?.message ||
        error?.response?.message ||
        error?.message ||
        null;

    if (errorMessages) {
        if (typeof errorMessages === "string") {
            errors.push(errorMessages);
        } else {
            for (let key in errorMessages) {
                Array.isArray(error.response.data.errors[key])
                    ? errors.push(
                        error.response.data.errors[key][0].replaceAll("_", "  ")
                    )
                    : errors.push(error.response.data.errors[key].replaceAll("_", "  "));
            }
        }
    }
    return errors.join(", ");
};

export const errorNotification = (error) => {
    app.$notify({
        text: filterAndConvertHttpErrorsToSingleString(error),
        type: "error",
        duration: 10000,
    });
};

export const isset = (payload) => {
    if (Array.isArray(payload)) {
        return payload.length > 0;
    }

    if (typeof payload == "object" && payload !== null) {
        return Object.keys(payload).length > 0;
    }

    return payload !== undefined && payload !== null && payload !== "";
};

export const defaultAgendaFormData = {
    appointment: {
        appointment_is_executed: 0,
        note: null,
        color: null,
    },
    type: "10241001",
    others: {},
    pending: {
        brand: {},
        vehicle: {},
        client: {},
        tyreSize: {},
        selectedWorkTypeRequiresTyreSize: false,
    },
};

export const defaultVehicleFormData = {
    vehicle: {
        brand: {},
    },
    client: {},
    vehicle_type_id: "10301000",
};

export const getEnv = (name) => {
    return window?.configs?.[name] || process.env[name];
};

export const humanReadableAddress = (address) => {
    let label = "";

    if (isset(address.building_id)) {
        label += address.building_id;
    }

    if (isset(address.street)) {
        if (label != "") {
            label += ", ";
        }
        label += address.street;
    }

    if (isset(address.zip)) {
        if (label != "") {
            label += ", ";
        }
        label += address.zip;
    }

    if (isset(address.city)) {
        if (label != "") {
            label += ", ";
        }
        if (typeof address.city === "object") {
            label += address.city?.name || "";
        } else {
            label += address.city;
        }
    }

    return label.toUpperCase();
};

export const abortPreviousRequestsAndGenerateSignal = () => {
    let previousSignals = app.$store.state.httpSignals;

    // loop through previous signals as an object and abort them
    for (let key in previousSignals) {
        previousSignals[key].abort();
    }

    // generate random new key for the new signal
    let newKey = Math.random().toString(36).substring(2, 15);

    // generate new signal
    let newSignal = new AbortController();

    // add new signal to the store
    app.$store.state.httpSignals = {};
    app.$store.state.httpSignals[newKey] = newSignal;

    // return the new signal
    return newSignal.signal;
};

export const translateTitle = (workType) => {
    let translation = app.$t("work_types." + workType.identifier);

    if (translation === "work_types." + workType.identifier + '_title') {
        return workType.name;
    }

    return translation;
};

export const limitInput = (e, maxLength) => {
    // if length is 3 prevent typing
    if (e.target.value.length === maxLength && e.key !== 'Backspace') {
        e.preventDefault();
    }

};

export const jumpToNextInput = (e, targetLength, elementId) => {
    if (e.target.value.length === targetLength && e.key !== 'Backspace') {
        let el = document.getElementById(elementId);
        if(el) el.focus();
    }
};