<template>
  <div>
    <a-select v-model:value="language">
      <template #suffixIcon><global-outlined /></template>
      <a-select-option
        v-for="item in languages"
        :key="item.id"
        :value="item.key"
        >{{ $t("app_languages." + item.key) }}</a-select-option
      >
    </a-select>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { GlobalOutlined } from "@ant-design/icons-vue";
import enUS from "ant-design-vue/es/locale/en_US";
import deDe from "ant-design-vue/es/locale/de_DE";
import esES from "ant-design-vue/es/locale/es_ES";
import frFr from "ant-design-vue/es/locale/fr_FR";
import nlNL from "ant-design-vue/es/locale/nl_NL";
import ptPT from "ant-design-vue/es/locale/pt_PT";
import roRO from "ant-design-vue/es/locale/ro_RO";

export default {
  name: "LanguageSwitch",
  components: {
    GlobalOutlined,
  },
  data() {
    return {
      language: null,
      localeMap: {
        en: enUS,
        de: deDe,
        es: esES,
        fr: frFr,
        nl: nlNL,
        pt: ptPT,
        ro: roRO,
      },
      dateFormatMap: {
        en: "DD/MM/YYYY",
        de: "DD.MM.YYYY",
        es: "DD/MM/YYYY",
        fr: "DD/MM/YYYY",
        nl: "DD-MM-YYYY",
        pt: "DD/MM/YYYY",
        ro: "DD.MM.YYYY",
      },
    };
  },
  async created() {
    await this.getCompanyLanguages();
    // Select Default Language
    let companyDefaultLanguage = this.companyLanguages.find(
      (item) => item.is_default == true
    );
    this.setLanguage(companyDefaultLanguage.language.key);
  },
  computed: {
    ...mapState(["languages", "selectedLanguage", "companyLanguages"]),
  },
  watch: {
    language: function (newVal) {
      this.setLanguage(newVal);
    },
  },
  methods: {
    ...mapActions(["getCompanyLanguages"]),
    setLanguage(language) {
      this.$store.commit("SET_SELECTED_LANGUAGE", language);
      document.documentElement.lang = language;
      this.$i18n.locale = language;
      this.language = language;

      this.$store.commit(
        "SET_DATE_FORMAT",
        this.dateFormatMap[language] ?? "MM/DD/YYYY"
      );
      this.$store.commit("SET_DATE_LOCALE", this.localeMap[language] ?? enUS);
    },
  },
};
</script>
